import React, { createContext, useReducer } from "react";
// import { startSpan, endSpan } from "../../helpFunctions/general";
import { DataStoreReducer } from "./DataStoreReducer";

export const DataStoreContext = createContext();

const initialState = {
  users: null,
  organizations: null,
  organization: null,
  sessions: null,
  scenarios: null,
  selectedUser: null,
  selectedOrg: null,
  loading: true,
  sessionsFromDate: null,
  personalSessions: null,
  settings: null,
  scenarioImages: null,
  scenarioForm: null,
  voices: null,
};

const DataStoreProvider = props => {
  const [state, dispatch] = useReducer(DataStoreReducer, initialState, () => {
    return initialState;
  });

  // const spanRef = useRef(null);

  // useEffect(() => {
  //   // Whenever we change to loading, we start a span (if not already), and whenever we change to not load
  //   // we end the span (if not already)
  //   if (state.loading) {
  //     startSpan({ spanRef, op: "db", description: "Firebase Data" });
  //   } else {
  //     endSpan(spanRef);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [state.loading]);

  return <DataStoreContext.Provider value={{ state, dispatch }}>{props.children}</DataStoreContext.Provider>;
};

export default DataStoreProvider;
