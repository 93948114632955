export const DataStoreReducer = (state, action) => {
  switch (action.type) {
    case "SET_USERS":
      return {
        ...state,
        users: action.users,
      };
    case "SET_ORGANIZATIONS":
      return {
        ...state,
        organizations: action.organizations,
      };
    case "SET_PERSONAL_SESSIONS":
      return {
        ...state,
        personalSessions: action.personalSessions,
      };
    case "SET_SESSIONS":
      return {
        ...state,
        sessions: action.sessions,
      };
    case "SET_SESSION_FROM_DATE":
      return {
        ...state,
        sessionsFromDate: action.sessionsFromDate,
      };
    case "SET_SCENARIOS":
      return {
        ...state,
        scenarios: action.scenarios,
        loading: false,
      };
    case "SET_SCENARIO_FORM":
      return {
        ...state,
        scenarioForm: action.scenarioForm,
      };
    case "SET_ORGANIZATION":
      return {
        ...state,
        organization: action.organization,
        loading: false,
      };
    case "SET_USER":
      return {
        ...state,
        user: action.user,
      };
    case "SET_SELECTED_USER":
      return {
        ...state,
        selectedUser: action.selectedUser,
      };
    case "SET_SELECTED_ORG":
      return {
        ...state,
        selectedOrg: action.selectedOrg,
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: action.loading,
      };
    case "SET_SETTINGS":
      return {
        ...state,
        settings: action.settings,
      };
    case "SET_SCENARIO_IMAGES":
      return {
        ...state,
        scenarioImages: action.scenarioImages,
      };
    case "SET_VOICES":
      return {
        ...state,
        voices: action.voices,
      };
    case "RESET_STATE":
      return {
        users: null,
        organization: null,
        organizations: null,
        sessions: null,
        scenarios: null,
        user: null,
        selectedUser: null,
        selectedOrg: null,
        loading: true,
        sessionsFromDate: null,
        personalSessions: null,
        settings: null,
        scenarioImages: null,
        scenarioForm: null,
        voices: null,
      };
    default:
      return state;
  }
};
