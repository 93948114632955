import React, { useCallback, useEffect, useRef, useState } from "react";
import { getAvatarName } from "../../constants/avatars";
import { compose } from "../../helpFunctions/general";
import CustomSelect from "../CustomSelect";
import { AvatarOption } from "../CustomSelect/customSelectOptions";
import { FrButton, FrError, FrInput } from "../DesignSystem/style";
import { SelectorContainer } from "../Selector";
import { withAuthorization } from "../Session";
import { CloseBtn, Iframe, InputContainer, Modal, ModalContent, Xbtn } from "./style";

const showRPMAvatar = Boolean(process.env.REACT_APP_RPM_AVATAR_DOMAIN);

export const getOptionList = (createdAvatars, avatars) => {
  let createdAvatarOptions = [];

  if (createdAvatars && createdAvatars.length > 0) {
    createdAvatarOptions = createdAvatars.map(item => {
      return {
        id: item.id,
        name: item.name,
        image: item.id,
        label: item.name,
      };
    });
  }
  createdAvatarOptions.push({
    id: "create",
    value: "create",
    label: "Create Avatar",
  });

  const optionList = [];
  if (showRPMAvatar) {
    optionList.push({
      label: "Created Avatars",
      options: createdAvatarOptions,
    });
  }
  optionList.push({
    label: "Default Avatars",
    options: Object.entries(avatars ?? {}).map(([key, avatar]) => ({
      id: avatar.avatarUrl,
      value: avatar.name,
      name: avatar.name,
      image: avatar.image,
      label: avatar.name,
    })),
  });

  return optionList;
};

const AvatarSelector = ({
  avatarId,
  knownAvatars,
  changedAvatarCallback,
  avatars,
  createdAvatars,
  disabled,
  error,
}) => {
  // avatars come from the database and is passed in as a prop from Settings.js
  const [selectedAvatarName, setSelectedAvatarName] = useState("");
  const [selectedRPMUrl, setSelectedRPMUrl] = useState("");

  const [namePopupActive, setNamePopupActive] = useState(false); // Popup that saves created avatar name
  const [createPopupActive, setCreatePopupActive] = useState(false); // Popup where user can create Avatar
  const iFrameRef = useRef(null);

  const subdomain = process.env.REACT_APP_RPM_AVATAR_DOMAIN;
  const avatarOptions = getOptionList(createdAvatars, avatars);

  const safeParse = event => {
    try {
      return JSON.parse(event.data);
    } catch (error) {
      return null;
    }
  };

  const subscribe = useCallback(event => {
    const json = safeParse(event);

    if (json?.source !== "readyplayerme") {
      return;
    }

    if (json.eventName === "v1.frame.ready") {
      const iFrame = iFrameRef.current;
      if (iFrame && iFrame.contentWindow) {
        iFrame.contentWindow.postMessage(
          JSON.stringify({
            target: "readyplayerme",
            type: "subscribe",
            eventName: "v1.**",
          }),
          "*"
        );
      }
    }

    if (json.eventName === "v1.avatar.exported") {
      console.log(`Avatar URL: ${json.data.url}`);
      setSelectedRPMUrl(json.data.url);
      setCreatePopupActive(false);
    }

    if (json.eventName === "v1.user.set") {
      console.log(`User with id ${json.data.id} set: ${JSON.stringify(json)}`);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("message", subscribe);
    document.addEventListener("message", subscribe);
    return () => {
      window.removeEventListener("message", subscribe);
      document.removeEventListener("message", subscribe);
    };
  }, [subscribe]);

  const newKnownAvatars = () => {
    const newKnownAvatars = knownAvatars ? [...knownAvatars] : [];
    // Only add new avatar if it doesn't already exist, or just update the name
    const existingIndex = newKnownAvatars.findIndex(item => item.id === selectedRPMUrl);
    const name = selectedAvatarName || "My Avatar";
    if (existingIndex > -1) {
      newKnownAvatars[existingIndex].name = name;
    } else {
      newKnownAvatars.push({ id: selectedRPMUrl, name });
    }
    return newKnownAvatars;
  };

  const completeAvatarCreation = () => {
    if (changedAvatarCallback) changedAvatarCallback(selectedRPMUrl, newKnownAvatars());

    setNamePopupActive(false);
  };

  const resetAllStates = () => {
    setSelectedRPMUrl("");
    setSelectedAvatarName("");
    setNamePopupActive(true);
  };

  return (
    <SelectorContainer fullWidth={"100%"} background={"var(--grey-5)"} margin={"5px 0 0"} padding={20}>
      <CustomSelect
        isSearchable={true}
        isMulti={false}
        isDisabled={disabled}
        onChange={selected => {
          if (selected.value === "create") {
            setCreatePopupActive(true);
            resetAllStates();
          } else {
            if (changedAvatarCallback) changedAvatarCallback(selected, knownAvatars);
          }
        }}
        options={avatarOptions ?? []}
        name={selectedAvatarName}
        value={avatarId}
        getOptionLabel={option => option.name || option.label}
        // Including knownAvatars because avatars created by other don't show up in the default avatars.
        placeholder={
          "Avatar: " + getAvatarName(avatarId, avatarOptions.flatMap(a => a.options).concat(knownAvatars)) ||
          "Select Avatar"
        }
        error={error}
        option={AvatarOption}
      />
      {error && <FrError>{error.message}</FrError>}
      {createPopupActive && (
        <Modal>
          <ModalContent>
            <CloseBtn>
              <Xbtn onClick={() => setCreatePopupActive(false)}>X</Xbtn>
            </CloseBtn>
            <Iframe
              ref={iFrameRef}
              title="readyPlayerMeFrame"
              id="frame"
              src={`https://${subdomain}.readyplayer.me/avatar?frameApi`}
              allow="camera *; microphone *; clipboard-write"
              width="100%"
              height="550px"
              sandbox="allow-scripts allow-same-origin"
            ></Iframe>
          </ModalContent>
        </Modal>
      )}
      {selectedRPMUrl && namePopupActive && (
        <Modal>
          <ModalContent padding="100px">
            <Xbtn></Xbtn>
            <InputContainer>
              <h4>Name Your Avatar</h4>
              <FrInput
                maxLength="40"
                placeholder={""}
                defaultValue="My Avatar"
                type="text"
                width="80%"
                onChange={e => setSelectedAvatarName(e.target.value)}
                background={"var(--grey-5)"}
              ></FrInput>
              <FrButton onClick={() => completeAvatarCreation()}>SAVE</FrButton>
            </InputContainer>
          </ModalContent>
        </Modal>
      )}
    </SelectorContainer>
  );
};

export default compose(withAuthorization(() => true))(AvatarSelector);
